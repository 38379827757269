import {
  FormRHFDrawerBody,
  FormRHFDrawerClose,
  FormRHFDrawerFooter,
  FormRHFDrawerRoot,
  FormRHFDrawerTitle,
} from './FormRHFDrawer';

export const FormRHFDrawer = {
  Root: FormRHFDrawerRoot,
  Title: FormRHFDrawerTitle,
  Body: FormRHFDrawerBody,
  Footer: FormRHFDrawerFooter,
  Close: FormRHFDrawerClose,
};

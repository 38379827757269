// eslint-disable-next-line import/no-unassigned-import
import '../polyfills';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { ReactElement } from 'react';
import { KbsProvider } from 'react-kbs';

import '../../tailwind.css';

import Layout from '../components/marketing/Layout';
import { DebugProvider } from '../contexts/debug_context';
import { initSentry } from '../sentry';

if (process.env.IS_PRODUCTION_BUILD && process.env.IS_ANALYTICS_ENABLED) {
  initSentry();
}

const client = new QueryClient({
  defaultOptions: {
    queries: {
      networkMode: 'online',
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      retry: false,
    },
  },
});

export default function App({ Component, pageProps }: AppProps): ReactElement {
  const { query } = useRouter();
  const isDebug = Boolean(query.debug);
  return (
    <>
      <Head>
        <title>NMRium</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <link rel="icon" href="/favicon.ico" />
        <link rel="manifest" href="/manifest.json" />
        <link
          href="/icons/icon-16x16.png"
          rel="icon"
          type="image/png"
          sizes="16x16"
        />
        <link
          href="/icons/icon-32x32.png"
          rel="icon"
          type="image/png"
          sizes="32x32"
        />
        <link rel="apple-touch-icon" href="/icons/apple-icon.png" />
        <meta name="theme-color" content="#ffffff" />
      </Head>

      <DebugProvider value={isDebug}>
        <QueryClientProvider client={client}>
          <KbsProvider>
            <Layout>
              <Component {...pageProps} />
            </Layout>
          </KbsProvider>
        </QueryClientProvider>
      </DebugProvider>
    </>
  );
}

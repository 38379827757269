import type { TextFormatType } from 'lexical';
import { FORMAT_TEXT_COMMAND } from 'lexical';
import { ReactNode } from 'react';

import { TranslationsText } from '../../../../../../internationalization/TranslationsText';
import { useRichTextContext } from '../../../context/RichTextContext';
import { RichTextFormatType } from '../../../context/RichTextProvider';
import { ToolbarPluginButton } from '../../ToolbarPlugin';
import { TooltipPluginElement } from '../../types';

interface FormatPluginProps {
  pluginKey: string;
  icon: ReactNode;
}

export default function FormatPlugin(
  props: FormatPluginProps,
): TooltipPluginElement {
  const { pluginKey, icon } = props;

  const [state, dispatch] = useRichTextContext();

  function onClick() {
    const textFormatKey = pluginKey as TextFormatType;
    const richTextFormatKey = pluginKey as RichTextFormatType;

    if (!state.activeEditor) {
      return;
    }

    state.activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, textFormatKey);

    dispatch({
      type: 'CHANGE_TOOLBAR',
      payload: {
        type: richTextFormatKey,
        value: !state[richTextFormatKey],
      },
    });
  }

  return (
    <ToolbarPluginButton
      onClick={onClick}
      id={pluginKey}
      tooltip={
        <TranslationsText
          textKey={`richtext.toolbar.text.format.${pluginKey}`}
        />
      }
      variant={!state[pluginKey as RichTextFormatType] ? 'white' : 'secondary'}
    >
      {icon}
    </ToolbarPluginButton>
  );
}

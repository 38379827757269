// Install polyfills for older browsers. Based on error reports.

/* eslint-disable import/no-unassigned-import */
import 'core-js/es/array/at';
import 'core-js/es/typed-array/at';
import 'core-js/es/string/replace-all';
/* eslint-enable import/no-unassigned-import */

import { Sentry } from './sentry';

// globalThis.reportError
if (typeof globalThis.reportError !== 'function') {
  globalThis.reportError = function reportError(error: unknown) {
    Sentry.captureException(error);
    // eslint-disable-next-line no-console
    console.error(error);
  };
}

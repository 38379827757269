import type { InitialEditorStateType } from '@lexical/react/LexicalComposer.js';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext.js';
import { useEffect } from 'react';

import { RICHTEXT_DEFAULT_VALUES } from './utils';

interface RichTextResetProps {
  state?: InitialEditorStateType;
}

/**
 * If props.state became to be RICHTEXT_DEFAULT_VALUES
 * Reset the form
 */
export function RichTextResetPlugin(props: RichTextResetProps) {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (props.state === RICHTEXT_DEFAULT_VALUES) {
      editor.setEditorState(editor.parseEditorState(props.state));
    }
  }, [editor, props.state]);

  return null;
}

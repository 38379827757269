import clsx from 'clsx';
import {
  createContext,
  ElementType,
  ReactElement,
  ReactNode,
  useContext,
  useMemo,
} from 'react';

import { Button, Color, Roundness, Size, Variant } from '../..';
import { Dropdown, DropdownProps } from '../dropdown/Dropdown';

import { ButtonProps } from './Button';
import { getButtonClassName } from './utils';

interface ButtonGroupContext {
  variant: Variant;
  color: Color;
  size: Size;
  roundness: 'full' | 'light';
  disabled: boolean;
}

const context = createContext<ButtonGroupContext | null>(null);

export interface ButtonGroupProps {
  variant?: Variant;
  color?: Color;
  children: ReactNode;
  size?: Size;
  roundness?: 'full' | 'light';
  disabled?: boolean;
}

export function ButtonGroup(props: ButtonGroupProps): ReactElement {
  const {
    children,
    variant = Variant.white,
    color = Color.primary,
    size = Size.medium,
    roundness = Roundness.light,
    disabled = false,
  } = props;

  const value = useMemo(() => {
    return { color, variant, size, roundness, disabled };
  }, [color, disabled, roundness, size, variant]);

  return (
    <context.Provider value={value}>
      <div className="inline-flex flex-row items-center shadow-sm">
        {children}
      </div>
    </context.Provider>
  );
}

export function ButtonGroupButton<T extends ElementType = 'button'>(
  props: ButtonProps<T>,
) {
  const ctx = useContext(context);

  if (ctx === null) {
    throw new Error('context for ButtonGroup was not provided');
  }

  const {
    variant = ctx.variant,
    color = ctx.color,
    size = ctx.size,
    roundness = ctx.roundness,
    disabled = ctx.disabled,
    className,
  } = props;

  return (
    <Button
      {...props}
      variant={variant}
      color={color}
      size={size}
      roundness={roundness}
      disabled={disabled}
      className={clsx(
        className,
        '-ml-px rounded-none focus:z-10 focus:ring-1',
        roundness === Roundness.full || roundness === Roundness.circular
          ? 'first:rounded-l-full last:rounded-r-full'
          : 'first:rounded-l-md last:rounded-r-md',
      )}
    />
  );
}

/**
 * @deprecated Use ButtonGroupButton instead.
 */
// eslint-disable-next-line deprecation/deprecation
ButtonGroup.Button = ButtonGroupButton;

export function ButtonGroupDropdown<T>(
  props: Omit<DropdownProps<T>, 'buttonClassName' | 'noDefaultButtonStyle'> &
    Pick<ButtonProps, 'variant' | 'color'>,
) {
  const ctx = useContext(context);

  if (ctx === null) {
    throw new Error('context for ButtonGroup was not provided');
  }

  const className = getButtonClassName({
    ...ctx,
    ...props,
  });

  return (
    <Dropdown
      {...props}
      className="-ml-px"
      buttonClassName={clsx(
        className,
        'rounded-l-none',
        ctx.roundness === Roundness.full ? 'rounded-r-full' : 'rounded-r-md',
      )}
      noDefaultButtonStyle
      {...ctx}
    />
  );
}

/**
 * @deprecated Use ButtonGroupDropdown instead.
 */
// eslint-disable-next-line deprecation/deprecation
ButtonGroup.Dropdown = ButtonGroupDropdown;

import {
  DrawerBody,
  DrawerClose,
  DrawerFooter,
  DrawerRoot,
  DrawerTitle,
} from './Drawer';

export const Drawer = {
  Root: DrawerRoot,
  Title: DrawerTitle,
  Body: DrawerBody,
  Footer: DrawerFooter,
  Close: DrawerClose,
};

import * as Sentry from '@sentry/react';

export * as Sentry from '@sentry/react';

export function initSentry() {
  Sentry.init({
    dsn: 'https://2f6e56796d914474b0e769d23054574e@o4505447292469248.ingest.sentry.io/4505447302430720',
    release: process.env.RELEASE_SHA
      ? `nmrium.org@${process.env.RELEASE_SHA}`
      : undefined,
    replaysSessionSampleRate: 0, // We only want to capture sessions where errors occur.
    replaysOnErrorSampleRate: 1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    integrations: [
      Sentry.extraErrorDataIntegration({
        // Limit of how deep the object serializer should go. Anything deeper than limit will
        // be replaced with standard Node.js REPL notation of [Object], [Array], [Function] or
        // a primitive value. Defaults to 3.
        depth: Infinity,
        captureErrorCause: true,
      }),
      Sentry.replayIntegration({
        blockAllMedia: false,
        maskAllInputs: false,
        maskAllText: false,
      }),
      Sentry.replayCanvasIntegration(),
    ],
    beforeSend(event) {
      if (shouldIgnoreEvent(event)) {
        return null;
      }
      return event;
    },
  });
}

function shouldIgnoreEvent(event: Sentry.Event): boolean {
  const firstException = event.exception?.values?.[0];
  if (!firstException) {
    return false;
  }

  // Ignore various network-related errors. We can't do anything about them.
  if (
    firstException.type === 'ChunkLoadError' ||
    ((firstException.value === 'Network request failed' ||
      firstException.value === 'AbortError: Aborted' ||
      firstException.type === 'AbortError') &&
      hasStatusCodeZero(event.breadcrumbs)) ||
    firstException.value === 'Failed to fetch' ||
    firstException.value?.startsWith('Loading CSS chunk') ||
    firstException.value?.includes('ServiceWorker')
  ) {
    return true;
  }

  // Ignore Chrome errors sending analytics to datami.
  if (
    firstException.stacktrace?.frames?.some((frame) =>
      frame.filename?.includes('datami'),
    )
  ) {
    return true;
  }

  // Ignore all "fetch" errors from Safari or Firefox because we can't identify them specifically.
  if (
    firstException.value === 'Load failed' ||
    firstException.value?.startsWith('NetworkError')
  ) {
    return true;
  }

  // TODO: Find out why this happens all the time when NMRium is loaded.
  if (firstException.value?.includes('ResizeObserver')) {
    return true;
  }

  return false;
}

function hasStatusCodeZero(breadcrumbs?: Sentry.Breadcrumb[]) {
  return (
    breadcrumbs?.some((breadcrumb) => {
      return (
        (breadcrumb.category === 'fetch' || breadcrumb.category === 'xhr') &&
        breadcrumb.data?.status_code === 0
      );
    }) || false
  );
}

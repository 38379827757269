import { ReactNode, useMemo } from 'react';

import { TransitionContext, transitionContext } from './useTransitionContext';

interface TransitionProviderProps extends TransitionContext {
  children: ReactNode;
}

export function TransitionProvider(props: TransitionProviderProps) {
  const { children, isOpen, timeout } = props;

  const value = useMemo(() => {
    return { isOpen, timeout };
  }, [isOpen, timeout]);

  return (
    <transitionContext.Provider value={value}>
      {children}
    </transitionContext.Provider>
  );
}

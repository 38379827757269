import { createContext, useContext } from 'react';

interface DebugFns {
  log: typeof console.log;
  warn: typeof console.warn;
  error: typeof console.error;
}

function noop() {
  // No-op.
}

const debugNoop: DebugFns = {
  log: noop,
  warn: noop,
  error: noop,
};

/* eslint-disable no-console */
const debugEnabled: DebugFns = {
  log: console.log,
  warn: console.warn,
  error: console.error,
};
/* eslint-enable no-console */

const debugContext = createContext<boolean>(false);

function useIsDebug() {
  return useContext(debugContext);
}

export function useDebug() {
  const isDebug = useIsDebug();
  return isDebug ? debugEnabled : debugNoop;
}

export const DebugProvider = debugContext.Provider;

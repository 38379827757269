import en from './translations/en.json';
import { useTranslations } from './useTranslations';

export type TranslationKeys = keyof typeof en;

interface TranslationsTextProps {
  textKey: TranslationKeys | string;
  values?: Record<string, string | number>;
}

export function TranslationsText(props: TranslationsTextProps) {
  const { textKey, values = {} } = props;

  let translation = useTranslations(textKey);

  for (const [key, value] of Object.entries(values)) {
    translation = translation.replace(`{${key}}`, String(value));
  }

  return <>{translation}</>;
}

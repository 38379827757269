import { FORMAT_ELEMENT_COMMAND } from 'lexical';
import { ReactNode } from 'react';

import { TranslationsText } from '../../../../../../internationalization/TranslationsText';
import { useRichTextContext } from '../../../context/RichTextContext';
import { RichTextBlockAlignmentType } from '../../../context/RichTextProvider';
import { ToolbarPluginButton } from '../../ToolbarPlugin';
import { TooltipPluginElement } from '../../types';

interface FormatAlignmentPluginProps {
  pluginKey: RichTextBlockAlignmentType;
  icon: ReactNode;
}

export default function FormatAlignmentPlugin(
  props: FormatAlignmentPluginProps,
): TooltipPluginElement {
  const { pluginKey, icon } = props;

  const [state, dispatch] = useRichTextContext();

  function onClick() {
    if (!state.activeEditor) {
      return;
    }

    state.activeEditor.dispatchCommand(FORMAT_ELEMENT_COMMAND, pluginKey);
    dispatch({ type: 'CHANGE_ALIGNMENT', payload: pluginKey });
  }

  return (
    <ToolbarPluginButton
      id={`align-to-${pluginKey}`}
      onClick={onClick}
      tooltip={
        <TranslationsText
          textKey={`richtext.toolbar.text.alignment.${pluginKey}`}
        />
      }
      variant={state.blockAlignment === pluginKey ? 'secondary' : 'white'}
    >
      {icon}
    </ToolbarPluginButton>
  );
}

import { ReactNode } from 'react';

import {
  useDropzoneFieldRHF,
  UseDropzoneFieldRHFOptions,
} from '../../hooks/useDropzone';
import { useInputId } from '../../hooks/useInputId';
import { Help, Label } from '../basic/common';
import { Dropzone, DropzoneProps } from '../basic/dropzone/Dropzone';
import { DropzoneList } from '../basic/dropzone/DropzoneList';
import {
  defaultErrorSerializer,
  FieldProps,
  RHFValidationProps,
} from '../util';

export type DropzoneFieldRHFProps = DropzoneFieldProps &
  FieldProps &
  RHFValidationProps;

interface DropzoneFieldProps
  extends UseDropzoneFieldRHFOptions,
    Pick<DropzoneProps, 'message' | 'header' | 'disabled'> {
  id?: string;
  name: string;
  label: ReactNode;
  hiddenLabel?: boolean;
  required?: boolean;
  showList?: boolean;
}

export function DropzoneFieldRHF(props: DropzoneFieldRHFProps) {
  const {
    message,
    header,
    id,
    name,
    label,
    hiddenLabel,
    required,
    disabled,
    serializeError = defaultErrorSerializer,
    ...otherProps
  } = props;
  const { dropzoneProps, dropzoneListProps, field, error } =
    useDropzoneFieldRHF(otherProps, name);

  const finalId = useInputId(id, name);

  return (
    <div>
      <Label
        id={finalId}
        text={label}
        required={required}
        disabled={disabled}
        hidden={hiddenLabel}
      />
      <Dropzone
        {...field}
        {...dropzoneProps}
        id={finalId}
        disabled={disabled}
        message={message}
        header={header}
      />
      {props.showList && <DropzoneList {...dropzoneListProps} />}
      <Help error={serializeError(error)} />
    </div>
  );
}

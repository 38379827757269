import { ReactNode, createContext, useContext, useMemo } from 'react';

import en from './translations/en.json';
import fr from './translations/fr.json';

export type Translations = Record<string, string>;

interface TranslationsContext {
  translations: Record<string, Translations>;
}

const translationsContext = createContext<TranslationsContext>({
  translations: {
    en,
    fr,
  },
});

interface TranslationsProviderProps {
  translations: TranslationsContext['translations'];
  children: ReactNode;
}

export function TranslationsProvider(props: TranslationsProviderProps) {
  const { children, translations } = props;
  const topLevel = useTranslationsProvider();

  const value = useMemo<TranslationsContext>(() => {
    const mergeTopLevel = merge({ en, fr }, topLevel);
    const mergeOverride = merge(mergeTopLevel, translations);

    return {
      translations: mergeOverride,
    };
  }, [topLevel, translations]);

  return (
    <translationsContext.Provider value={value}>
      {children}
    </translationsContext.Provider>
  );
}

export function useTranslationsProvider() {
  const context = useContext(translationsContext);
  return context.translations;
}

function merge(
  obj: Record<string, Translations>,
  parse: Record<string, Translations>,
): Record<string, Translations> {
  const newObj = obj;

  for (const [lang, values] of Object.entries(parse)) {
    for (const [key, value] of Object.entries(values)) {
      newObj[lang] = {
        ...newObj[lang],
        [key]: value,
      };
    }
  }

  return newObj;
}

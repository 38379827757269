import clsx from 'clsx';
import { useRouter } from 'next/dist/client/router';
import Link from 'next/link';
import React, { useMemo } from 'react';

import { header } from '../../routes';
import {
  HorizontalNavigationItem,
  HorizontalNavigationOption,
  UncontrolledHorizontalNavigation,
} from '../tailwind-ui';

export default function Navbar(props: { fixed: boolean }) {
  const { pathname } = useRouter();

  const options: Array<HorizontalNavigationOption<string>> = useMemo(() => {
    return header.map((route) => ({
      label: route.name,
      value: route.to,
    }));
  }, []);

  const selected = useMemo(() => {
    return options.find((opt) => {
      if (opt.value !== '/') {
        return pathname.startsWith(opt.value);
      } else {
        return pathname === opt.value;
      }
    });
  }, [pathname, options]);

  return (
    <header
      className={clsx('w-full border-b border-neutral-200 bg-white', {
        'fixed top-0 z-50': props.fixed,
      })}
    >
      <div className="mx-auto flex w-full max-w-7xl flex-row justify-center md:justify-between md:px-5 lg:px-8">
        <div className="hidden items-center md:flex">
          <NavbarBrand />
        </div>
        <div className="flex-1 sm:shrink-0 sm:grow-0">
          <UncontrolledHorizontalNavigation
            bare
            renderNavigationItem={(option) => {
              return (
                <HorizontalNavigationItem
                  key={option.value}
                  option={option}
                  isSelected={option === selected}
                  renderOption={(BaseOption, option) => {
                    const actualOption = header.find(
                      (head) => head.to === option.value,
                    );

                    return (
                      <Link href={option.value} prefetch={false}>
                        <BaseOption>
                          {option.label}{' '}
                          {actualOption?.isNew && (
                            <span className="text-success-700">•</span>
                          )}
                        </BaseOption>
                      </Link>
                    );
                  }}
                />
              );
            }}
            renderSelectedOption={() => (
              <div className="flex flex-1 flex-row items-center justify-between">
                <span>{selected?.label}</span>
                <NavbarBrand />
              </div>
            )}
            options={options}
          />
        </div>
      </div>
    </header>
  );
}

function NavbarBrand() {
  return (
    <Link href="/">
      <img
        width={112}
        height={33}
        src="/brand/nmrium-logo.svg"
        alt="NMRium logo"
      />
    </Link>
  );
}

import { ReactNode, createContext, useContext, useMemo } from 'react';

const localeContext = createContext<string>('en');

interface LocaleProviderProps {
  locale: string;
  children: ReactNode;
}

export function LocaleProvider(props: LocaleProviderProps) {
  const { children, locale } = props;

  const value = useMemo<string>(() => {
    return locale;
  }, [locale]);

  return (
    <localeContext.Provider value={value}>{children}</localeContext.Provider>
  );
}

export function useLocaleProvider() {
  const context = useContext(localeContext);
  return context;
}

import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { parse, ParseResult } from 'mrz';
import { useMemo, useState } from 'react';

import { TranslationsText } from '../../internationalization/TranslationsText';
import { Tooltip } from '../popper/Tooltip';

export interface MRZProps {
  /**
   * MRZ code
   */
  mrz: string | string[];
  /**
   * option to activate the autocorrection of the characters when is possible
   * @default false
   */
  autocorrect?: boolean;
  /**
   * Only show the details table about the errors or autocorrected values.
   * If the MRZ has no errors or autocorrected values, the table is not shown.
   */
  describeErrorsOnly?: boolean;
}

export function MRZ(props: MRZProps) {
  const { mrz, autocorrect = false, describeErrorsOnly = false } = props;
  const [hovered, setHovered] = useState<string | null>(null);

  const { lines, parsedMrz } = useMemo(() => {
    const lines = typeof mrz === 'string' ? mrz.split(/[\r\n]+/) : mrz;
    const parsedMrz = parse(lines, { autocorrect });
    return { lines, parsedMrz };
  }, [mrz, autocorrect]);

  const details = describeErrorsOnly
    ? parsedMrz.details.filter(
        (detail) => Boolean(detail.error) || detail.autocorrect.length > 0,
      )
    : parsedMrz.details;

  return (
    <div>
      <div className="inline-block rounded-md border px-7 py-2 font-ocrb">
        {lines.map((line, i) => (
          <MRZLine
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            index={i}
            line={line}
            parsedMrz={parsedMrz}
            hovered={hovered}
            setHovered={setHovered}
          />
        ))}
      </div>
      {details.length > 0 && (
        <table className="mt-4">
          <thead>
            <tr>
              <th className="border px-5 py-2">
                <TranslationsText textKey="mrz.table.name" />
              </th>
              <th className="border px-5 py-2">
                <TranslationsText textKey="mrz.table.value" />
              </th>
            </tr>
          </thead>
          <tbody className="cursor-default">
            {details.map(
              ({ label, value, valid, error, autocorrect, field }) => (
                <tr
                  key={label}
                  className={clsx({ 'bg-primary-300': hovered === label })}
                  onMouseEnter={() => setHovered(label)}
                  onMouseLeave={() => setHovered(null)}
                >
                  <td className="border px-3 py-1">
                    <div className="flex flex-row items-center justify-between">
                      <TranslationsText
                        textKey={`mrz.${field || 'separator'}`}
                      />
                      {autocorrect.length === 0 ? null : (
                        <Tooltip
                          content={
                            <TranslationsText
                              textKey="mrz.tooltip.correction"
                              values={{ corrections: autocorrect.length }}
                            />
                          }
                        >
                          <ExclamationCircleIcon className="ml-5 h-5 w-5 text-danger-600" />
                        </Tooltip>
                      )}
                    </div>
                  </td>
                  <td className="border px-3 py-1">
                    {valid && value}
                    {error && (
                      <span className="text-sm font-bold text-danger-500">
                        {error}
                      </span>
                    )}
                  </td>
                </tr>
              ),
            )}
          </tbody>
        </table>
      )}
    </div>
  );
}

interface MRZLineProps {
  index: number;
  line: string;
  parsedMrz: ParseResult;
  hovered: string | null;
  setHovered: (newHover: string | null) => void;
}

function MRZLine(props: MRZLineProps) {
  const { index, line, parsedMrz, hovered, setHovered } = props;
  const chars = line.split('');
  return (
    <div>
      {chars.map((char, j) => {
        const detail = parsedMrz.details.find(
          (detail) =>
            detail.line === index && detail.start <= j && detail.end > j,
        );
        return detail ? (
          <span
            // eslint-disable-next-line react/no-array-index-key
            key={j}
            className={clsx(
              'cursor-pointer',
              getCharacterColor(
                detail.valid,
                hovered === detail.label,
                !!detail.autocorrect.find(
                  (correction) =>
                    correction.line === index && correction.column === j,
                ),
              ),
            )}
            onMouseEnter={() => setHovered(detail.label)}
            onMouseLeave={() => setHovered(null)}
          >
            {char}
          </span>
        ) : (
          // eslint-disable-next-line react/no-array-index-key
          <span key={j}>{char}</span>
        );
      })}
    </div>
  );
}

function getCharacterColor(
  isValid: boolean,
  isHighlighted: boolean,
  isCorrected: boolean,
): string | null {
  if (isHighlighted) {
    return 'bg-primary-300';
  } else if (isCorrected) {
    return 'bg-warning-300';
  } else if (isValid) {
    return 'bg-success-300';
  } else {
    return 'bg-danger-300';
  }
}

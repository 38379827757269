import clsx from 'clsx';
import { DetailedHTMLProps, ImgHTMLAttributes } from 'react';

export type ZoomImageInOverlayProps = DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>;

export function ZoomImageInOverlay(props: ZoomImageInOverlayProps) {
  return (
    <img
      {...props}
      className={clsx(props.className, zoomImageInOverlayStyling())}
    />
  );
}

export function zoomImageInOverlayStyling() {
  return 'block max-h-full max-w-full object-scale-down shadow-[0_0_25px_5px_rgba(0,0,0,0.25)]';
}

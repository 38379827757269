import ScrollButton from '../ScrollButton';

export default function Footer() {
  return (
    <div className="relative z-50 w-full bg-white">
      <div className="mx-auto flex max-w-screen-xl flex-col gap-y-4 overflow-hidden px-4 pb-5 sm:gap-y-4 sm:px-6 lg:px-8">
        <div className="text-right">
          <ScrollButton />
        </div>
        <div className="flex flex-col items-center gap-y-5 sm:flex-row sm:items-end sm:justify-between">
          <a
            href="https://www.zakodium.com"
            target="_blank"
            rel="noopener noreferrer"
            className="w-32 sm:w-fit"
          >
            <img
              width={150}
              src="/brand/zakodium-brand.svg"
              alt="Zakodium brand"
            />
          </a>
          <p className="text-center text-sm leading-6 text-neutral-500 sm:text-base">
            &copy; Zakodium Sàrl. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  );
}

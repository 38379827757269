export interface Route {
  to: string;
  name: string;
  footer?: boolean; // true if the route should be in footer too
  header?: boolean; // true if the route should be in header too
  isFooterVisible?: boolean;
  isNmrium?: boolean;
  isNew?: boolean;
  isWhite?: boolean;
  embedded?: boolean;
}

const routes: Route[] = [
  {
    to: '/',
    name: 'Home',
    footer: true,
    header: true,
    isFooterVisible: true,
    embedded: false,
  },
  {
    to: '/nmrium',
    name: 'NMRium',
    footer: true,
    header: true,
    isFooterVisible: false,
    isNmrium: true,
    isWhite: true,
    embedded: false,
  },
  {
    to: '/nmrium/embed',
    name: 'NMRium embed',
    footer: false,
    header: false,
    isFooterVisible: false,
    isWhite: true,
    embedded: true,
    isNmrium: true,
  },
  {
    to: '/features',
    name: 'Features',
    footer: true,
    header: true,
    isFooterVisible: false,
    isWhite: false,
    embedded: false,
  },
  {
    to: '/teaching',
    name: 'Teaching',
    footer: true,
    header: true,
    isFooterVisible: false,
    isWhite: true,
    embedded: false,
  },
  {
    to: '/teaching/exercises',
    name: 'Exercises',
    header: false,
    footer: true,
    isFooterVisible: false,
    isNmrium: true,
    isWhite: true,
    embedded: false,
  },
  {
    to: '/tutorials',
    name: 'Tutorials',
    footer: true,
    header: true,
    isFooterVisible: false,
    isWhite: false,
    embedded: false,
  },
  {
    to: '/services',
    name: 'Services',
    footer: true,
    header: true,
    isFooterVisible: true,
    isWhite: true,
    embedded: false,
  },
  {
    to: '/prediction',
    name: 'Prediction',
    footer: false,
    header: false,
    isFooterVisible: true,
    isWhite: true,
    embedded: false,
  },
  {
    to: '/predict',
    name: 'Predict',
    footer: false,
    header: false,
    isFooterVisible: false,
    isWhite: true,
    embedded: false,
    isNmrium: true,
  },
  {
    to: '/predict/embed',
    name: 'Predict embed',
    footer: false,
    header: false,
    isFooterVisible: false,
    isWhite: true,
    embedded: true,
  },
  {
    to: '/simulation',
    name: 'Simulation',
    footer: false,
    header: false,
    isFooterVisible: false,
    isWhite: true,
    embedded: false,
  },
  {
    to: '/about',
    name: 'About us',
    footer: true,
    header: true,
    isFooterVisible: true,
    isWhite: false,
    embedded: false,
  },
  {
    to: '/contact',
    name: 'Contact us',
    footer: true,
    header: true,
    isFooterVisible: true,
    isWhite: false,
    embedded: false,
  },
];

export default routes;
export const header = routes.filter((r) => r.header);
export const footer = routes.filter((r) => r.footer);

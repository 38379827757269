export const DrawerSize = {
  xSmall: 'max-w-xs',
  small: 'max-w-sm',
  medium: 'max-w-md',
  large: 'max-w-lg',
  xLarge: 'max-w-xl',
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type DrawerSize = keyof typeof DrawerSize;

import { PencilSquareIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  forwardRef,
  ReactNode,
} from 'react';

import { Color } from '../../types';
import { Tooltip, WithTooltipProps } from '../popper/Tooltip';

type IconButtonSize = '4' | '5' | '6';
type ButtonProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

export interface IconButtonProps
  extends Omit<ButtonProps, 'title' | 'children'>,
    WithTooltipProps {
  size: IconButtonSize;
  icon?: ReactNode;
  color?: Color | 'none';
  noBlock?: boolean;
}

const colors: Record<Color, string> = {
  primary: 'text-primary-600',
  neutral: 'text-neutral-600',
  success: 'text-success-600',
  warning: 'text-warning-600',
  danger: 'text-danger-600',
  alternative: 'text-alternative-600',
};

const sizes: Record<IconButtonSize, string> = {
  4: 'h-4 w-4',
  5: 'h-5 w-5',
  6: 'h-6 w-6',
};

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  function IconButtonInternal(props: IconButtonProps, ref) {
    const {
      onClick,
      size,
      icon = <PencilSquareIcon />,
      color = Color.neutral,
      className,
      style,
      noBlock,

      tooltip,
      tooltipDelay,
      tooltipPlacement,

      ...others
    } = props;

    return (
      <Tooltip
        content={tooltip}
        delay={tooltipDelay}
        placement={tooltipPlacement}
      >
        <button
          type="button"
          ref={ref}
          onClick={onClick}
          style={style}
          className={clsx(color !== 'none' && colors[color], className, {
            block: !noBlock,
          })}
          {...others}
        >
          <div className={sizes[size]}>{icon}</div>
        </button>
      </Tooltip>
    );
  },
);

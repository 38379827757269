import { EditorState } from 'lexical';
import { useCallback } from 'react';
import { useController } from 'react-hook-form';

import { useCheckedFormRHFContext } from '../../hooks/useCheckedFormRHF';
import { RichText, RichTextProps } from '../basic/richtext/RichText';
import { defaultErrorSerializer, ErrorSerializer } from '../util';

export interface RichTextFieldRHFProps
  extends Omit<RichTextProps, 'onChange' | 'editorState' | 'error'> {
  name: string;
  serializeError?: ErrorSerializer;
  deps?: string[];
}

export function RichTextFieldRHF(props: RichTextFieldRHFProps) {
  const {
    name,
    serializeError = defaultErrorSerializer,
    deps,
    ...otherProps
  } = props;

  const { trigger } = useCheckedFormRHFContext();
  const {
    field,
    fieldState: { error },
    formState: { isSubmitted },
  } = useController({ name });

  const { value } = field;

  const setFieldValue = useCallback(
    (state: EditorState) => {
      const newValue = JSON.stringify(state);

      field.onChange(newValue);

      if (deps && isSubmitted) {
        void trigger(deps);
      }
    },
    [field, deps, isSubmitted, trigger],
  );

  return (
    <RichText
      {...otherProps}
      editorState={value}
      onChange={setFieldValue}
      error={serializeError(error)}
    />
  );
}

import * as RadixSwitch from '@radix-ui/react-switch';
import clsx from 'clsx';

import { useInputId } from '../../hooks/useInputId';

import { Help, HelpPublicProps } from './common';
import { labelColor, labelDisabledColor } from './utils.common';

export interface ToggleProps {
  label: string;
  activated: boolean;
  onChange: (activated: boolean) => void;
  disabled?: boolean;
  className?: string;
  size?: ToggleSize;
  id?: string;
  name?: string;
  error?: HelpPublicProps['error'];
}

export const ToggleSize = {
  Small: 'Small',
  Large: 'Large',
} as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ToggleSize = (typeof ToggleSize)[keyof typeof ToggleSize];

export function Toggle(props: ToggleProps) {
  const {
    error,
    size = 'Small',
    label,
    disabled,
    activated,
    onChange,
    id,
    name,
    className,
  } = props;

  const finalId = useInputId(id, name);

  return (
    <div className="flex flex-col">
      <div className="flex flex-row items-center gap-5">
        <RadixSwitch.Root
          checked={activated}
          disabled={disabled}
          onCheckedChange={onChange}
          id={finalId}
          name={name}
          className={clsx(
            'relative flex items-center rounded-full outline-none disabled:bg-neutral-100',
            size === ToggleSize.Large
              ? 'h-6 w-11 border-2 border-transparent'
              : 'h-4 w-10',
            className,
            disabled ? 'cursor-default' : 'cursor-pointer',
            activated && !error ? 'bg-primary-600' : 'bg-neutral-200',
          )}
        >
          <RadixSwitch.Thumb
            className={clsx(
              'block h-5 w-5 translate-x-0 rounded-full shadow ring-0 transition-transform duration-100 will-change-transform data-[state=checked]:translate-x-5',
              error ? 'bg-danger-600' : 'bg-white',
            )}
          />
        </RadixSwitch.Root>
        <label
          htmlFor={finalId}
          className={clsx(
            'text-sm font-semibold',
            disabled ? labelDisabledColor : labelColor,
          )}
        >
          {label}
        </label>
      </div>
      {error && <Help error={error} />}
    </div>
  );
}

import { ElementType } from 'react';

import { Button, ButtonProps } from '../../elements/buttons/Button';

export type ResetProps<T extends ElementType = 'button'> = Omit<
  ButtonProps<T>,
  'type'
>;

export function ResetButtonRHF<T extends ElementType = 'button'>(
  props: ResetProps<T>,
) {
  const { as, ...otherProps } = props;

  return (
    <Button
      type="reset"
      variant="secondary"
      color="warning"
      as={as as ElementType}
      {...otherProps}
    />
  );
}

export function ZakodiumSolidSvg({ className }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1026.68 254.66"
      className={className}
    >
      <g>
        <g>
          <path d="M305.52,170.83c2.24-1.87,5.14-3.24,7.53-4.93a127,127,0,0,0,16.09-13.36c10.84-10.7,21.5-26,16.64-41.94a31.2,31.2,0,0,0-18.36-20.28,90.91,90.91,0,0,0-20.39-5,191.12,191.12,0,0,0-37-1.14,372.87,372.87,0,0,0-56.73,8c-30,6.36-60,14.22-88.42,25.7-14.93,6-29.66,12.6-44.19,19.52-21.41,10.19-42.58,21.19-62.38,34.27-3.29,2.18-6.55,4.43-9.74,6.76C5.74,180.56,3,183.3,0,185.12c0,0,105.15-63.49,221-85.71S363.45,122.42,305.52,170.83Z" />
          <path d="M290.34,63.9c-.26-2.17.71-5.09.9-7.27a104.35,104.35,0,0,0,.1-17.41c-.69-8.67-2.62-17.54-7-25.12-3.07-5.29-8.08-11.25-14.19-13-8.8-2.51-18.68-.38-26.95,3C228.6,10,216.5,20.63,205.66,31.8c-7.4,7.63-14.47,15.57-21.27,23.74q-14.89,17.9-28,37.19a446.25,446.25,0,0,0-28.94,49q-5.47,10.82-10.3,22-2.49,5.72-4.78,11.53c-1.52,3.86-2.6,8.18-4.46,11.88,2-3.92,4.06-7.79,6.15-11.66q8.09-14.94,16.8-29.53,11.92-20,25-39.3a507,507,0,0,1,30.83-40.94,268.24,268.24,0,0,1,43-41.15A65.36,65.36,0,0,1,241,17.45c6.25-3,13.36-4.95,20.32-3.81,9.29,1.52,15.89,8.58,20.05,16.63C286.7,40.6,289,52.45,290.34,63.9Z" />
          <circle cx="96.17" cy="219.98" r="18.15" />
          <path d="M284.62,236.17v18.35H205.2l-.15.14H181.86l.14-.14,14.1-18.35,25.25-32.74,11.59-15,14.1-18.2H181.71V151.9H284.18l-14.24,18.35-25.55,33.18-11.59,15L219,236.17Z" />
          <path d="M403.82,254.66H383.27l-9.4-18.35-22.46-44-22.46,44-9.4,18.35H299l9.4-18.35,43-84.41,43,84.41Z" />
          <path d="M476.33,193.15l44.48,61.51h-22.6l-35.53-49-26,23.64v25.39H418.34V151.9h18.35v52.56l15.13-13.8,13.65-12.48L494.1,151.9h27.15Z" />
          <path d="M621.52,203.28a51.39,51.39,0,1,1-51.38-51.38A51.44,51.44,0,0,1,621.52,203.28Zm-18.35,0a33,33,0,1,0-33,33A33.08,33.08,0,0,0,603.17,203.28Z" />
          <path d="M689.63,152.05a51.24,51.24,0,0,1,39.2,84.41c-.88,1-1.91,2-2.94,3.08a50.43,50.43,0,0,1-36.11,15.12H638.4V151.9Zm-33,84.41h33a33,33,0,0,0,0-66.06h-33Z" />
          <path d="M761.7,254.66V151.9h18.36V254.66Z" />
          <path d="M903.37,151.9v51.38a51.39,51.39,0,0,1-102.77,0V151.9H819v51.38a33,33,0,0,0,66.07,0V151.9Z" />
          <path d="M1026.68,151.9V254.66h-18.35V189.19L990.27,203l-15,11.6-15-11.6-18.06-13.8v65.47H923.91V151.9L942.26,166l33,25.4,33-25.4Z" />
        </g>
      </g>
    </svg>
  );
}

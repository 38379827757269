import {
  CSSProperties,
  MouseEvent,
  ReactElement,
  Ref,
  useCallback,
  useMemo,
} from 'react';

import { Badge, BadgeVariant } from '../../elements/badge/Badge';
import { Color } from '../../types';
import { forwardRefWithGeneric } from '../../util';
import {
  defaultCanCreate,
  defaultGetBadgeColor,
  defaultGetValue,
  defaultIsOptionRemovable,
  defaultRenderCreate,
  defaultRenderOption,
} from '../../utils/defaultSearchSelectUtils';
import { useSearchSelectInternals } from '../../utils/hooks/useSearchSelectInternals';
import {
  InternalMultiSearchSelect,
  IsOptionRemovableCallback,
} from '../../utils/search-select-utils';

import { SearchSelectProps, SimpleSearchSelectProps } from './SearchSelect';
import { SimpleSelectOption } from './Select';

interface VariantBadgeProps {
  variant: BadgeVariant;
  color: Color;
  dot?: boolean;
}

interface ColoredBadgeProps {
  variant: 'COLORED_DOT';
  customColor: CSSProperties['color'];
  dot?: true;
}

interface CustomColoredBadgeProps {
  variant: 'CUSTOM_COLOR';
  backgroundColor: CSSProperties['color'];
  textColor: CSSProperties['color'];
  dot?: boolean;
  rounded?: boolean;
}

type GetBadgeColorReturn<T> = (
  option: T,
) => Color | CustomColoredBadgeProps | ColoredBadgeProps | VariantBadgeProps;

export interface SimpleMultiSearchSelectProps<OptionType>
  extends Omit<SimpleSearchSelectProps<OptionType>, 'selected' | 'onChange'> {
  selected: OptionType[];
  onChange: (newSelected: OptionType[]) => void;
  getBadgeColor?: GetBadgeColorReturn<OptionType>;
  isOptionRemovable?: IsOptionRemovableCallback<OptionType>;
}

export interface MultiSearchSelectProps<OptionType>
  extends Omit<SearchSelectProps<OptionType>, 'selected' | 'onChange'> {
  name: string;
  selected: OptionType[];
  onChange: (newSelected: OptionType[]) => void;
  getBadgeColor?: GetBadgeColorReturn<OptionType>;
  isOptionRemovable?: IsOptionRemovableCallback<OptionType>;
}

export const MultiSearchSelect = forwardRefWithGeneric(
  MultiSearchSelectForwardRef,
);

function MultiSearchSelectForwardRef<OptionType>(
  props: OptionType extends SimpleSelectOption
    ? SimpleMultiSearchSelectProps<OptionType>
    : MultiSearchSelectProps<OptionType>,
  ref: Ref<HTMLInputElement>,
): ReactElement {
  const {
    onSearchChange,
    options,
    onChange,
    getBadgeColor = defaultGetBadgeColor,
    isOptionRemovable = defaultIsOptionRemovable,
    selected,
    getValue = defaultGetValue,
    renderOption = defaultRenderOption,
    renderSelectedOption,
    closeListOnSelect = false,
    clearSearchOnSelect = true,
    onCreate,
    canCreate = defaultCanCreate,
    renderCreate = defaultRenderCreate,
    clearable = true,
    disabled = false,
    ...otherProps
  } = props;

  const finalRenderSelectedOption = renderSelectedOption || renderOption;

  const nonRemovableValues = useMemo(
    () => selected.filter((value) => !isOptionRemovable(value)),
    [selected, isOptionRemovable],
  );

  const renderedSelected = useMemo(() => {
    return selected.map((option) => {
      const value = getValue(option);
      const rendered = finalRenderSelectedOption(option);

      function handleDismiss(event: MouseEvent) {
        event.preventDefault();
        onChange(selected.filter((option) => getValue(option) !== value));
      }

      const onDismiss =
        disabled || !isOptionRemovable(option) ? undefined : handleDismiss;
      const badgeProps = getBadgeColor(option);

      // This is a tailwind badge color
      if (typeof badgeProps === 'string') {
        return (
          <Badge
            variant="COLORED_BACKGROUND"
            color={badgeProps}
            label={rendered}
            key={value}
            rounded
            onDismiss={onDismiss}
          />
        );
      }

      return (
        <Badge
          key={value}
          label={rendered}
          {...badgeProps}
          onDismiss={onDismiss}
        />
      );
    });
  }, [
    selected,
    getValue,
    finalRenderSelectedOption,
    onChange,
    getBadgeColor,
    disabled,
    isOptionRemovable,
  ]);

  const onSelect = useCallback(
    (value: OptionType | undefined) => {
      if (value === undefined) {
        onChange(nonRemovableValues);
      } else {
        onChange([...selected, value]);
      }
    },
    [selected, onChange, nonRemovableValues],
  );

  const handleBackspace = useCallback(() => {
    if (
      selected.length > 0 &&
      isOptionRemovable(selected[selected.length - 1])
    ) {
      onChange(selected.slice(0, -1));
    }
  }, [isOptionRemovable, onChange, selected]);

  const internalProps = useSearchSelectInternals({
    showSelected: false,
    searchValue: props.searchValue,
    onSearchChange,
    options,
    onSelect,
    getValue,
    renderOption,
    closeListOnSelect,
    clearSearchOnSelect,
    onCreate,
    canCreate,
    renderCreate,
    isOptionRemovable,
    onBackspace: handleBackspace,
    selected,
    pinSelectedOptions: false,
  });

  return (
    <InternalMultiSearchSelect
      {...internalProps}
      {...otherProps}
      inputRef={ref}
      clearable={clearable}
      disabled={disabled}
      hasClearableValue={selected.length !== nonRemovableValues.length}
      selectedBadges={renderedSelected}
    />
  );
}
